<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <div v-for="(node, index) in $breadcrumb" :key="`breadcrumb-${index}`">
        <div class="breadcrumb sm:flex"
          v-if="$breadcrumb.length === index + 1 || $breadcrumb[index+1].title !== node.title">
          <ChevronRightIcon v-if="index !== 0" class="breadcrumb__icon" />
          <router-link v-if="node.to && $breadcrumb.length !== index + 1" :to="node.to">{{ node.title }}</router-link>
          <span v-else class="breadcrumb--active">{{ node.title }}</span>
        </div>
      </div>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <!-- <div class="intro-x relative mr-3 sm:mr-6">
      <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input input placeholder-theme-13"
          placeholder="Search..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon dark:text-gray-300" />
      </div>
      <a class="notification sm:hidden" href>
        <SearchIcon class="notification__icon dark:text-gray-300" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  class="rounded-full"
                  src="/images/no_photo.png"
                />
              </div>
              <div class="ml-3">Tester</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                test@cloudfun.com.tw
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full"
                src="/images/no_photo.png"
              />
            </div>
            <div class="ml-3">名稱</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              分類
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div class="intro-x dropdown relative mr-auto sm:mr-6" v-if="hasOrders || hasContacts">
      <div class="dropdown-toggle notification notification--bullet cursor-pointer">
        <BellIcon class="notification__icon dark:text-gray-300" />
      </div>
      <div class="notification-content pt-2 dropdown-box">
        <div class="notification-content__box dropdown-box__content box dark:bg-dark-6">
          <div class="notification-content__title">訊息通知</div>
          <div class="cursor-pointer relative flex items-center mb-5">
            <div v-if="hasOrders" class="w-full ml-2 overflow-hidden">
              <router-link to="order" class="cursor-pointer">訂單管理 ></router-link>
            </div>
            <div v-if="hasContacts" class="w-full ml-2 overflow-hidden">
              <router-link to="customer-contact" class="cursor-pointer">聯絡我們 ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
        <img alt="Midone Tailwind HTML Admin Template" :src="$user.PhotoUri ?? '/images/no_photo.png'"
          onerror="this.onerror=null;this.src='/images/no_photo.png'" />
      </div>
      <div class="dropdown-box w-56">
        <div class="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white">
          <div class="p-4 border-b border-theme-40 dark:border-dark-3">
            <div class="text-xs text-theme-41 mt-0.5 dark:text-gray-600">
              Signed in as:
            </div>
            <div class="font-medium">{{ $user.Name }}<span v-if="!isGuest">({{$user.Account}})</span></div>
          </div>
          <div class="p-2" v-show="!isGuest">
            <!-- <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
              <LockIcon class="w-4 h-4 mr-2" /> 變更密碼
            </a> -->
            <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="logout()">
              <ToggleLeftIcon class="w-4 h-4 mr-2" /> 登出
            </a>
          </div>
          <div class="p-2 border-t border-theme-40 dark:border-dark-3">
            <a v-if="isGuest"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="logout()">
              <ToggleRightIcon class="w-4 h-4 mr-2" /> 登入
            </a>
            <!-- <a class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
              <HelpCircleIcon class="w-4 h-4 mr-2" /> 支援服務
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script lang="ts">
import CloudFun, { Condition, Operator } from '@cloudfun/core'
import { defineComponent, onMounted, ref } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  setup() {
    const model = CloudFun.current?.model;
    const hasOrders = ref(false);
    const hasContacts = ref(false);
    const searchDropdown = ref(false)

    onMounted(() => {
      setInterval(async () => {
        let temp: any;
        temp = await model?.dispatch("order/query", { condition: new Condition("Status", Operator.Equal, (model.enums as any).OrderStatus.PaymentConfirmed.Value), page: 1, pageSize: 1 });
        hasOrders.value = !!temp.data?.[0];
        temp = await model?.dispatch("customerContact/query", { condition: new Condition("ReplyContent", Operator.IsNull), page: 1, pageSize: 1 })
        hasContacts.value = !!temp.data?.[0];
      }, 60000);
    });
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      hasOrders,
      hasContacts,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      isGuest: !CloudFun.current?.user.Id
    }
  },
  computed: {
    ...mapGetters(['user']),
    homePath() {
      const path = this.$route.path
      if (path.startsWith('/simple-menu')) return '/simple-menu'
      if (path.startsWith('/top-menu')) return '/top-menu'
      return '/'
    }
  },
  methods: {
    logout() {
      if (!this.$user.Id) {
        this.$router.push('/login')
        return
      }
      this.$model.dispatch('logout').finally(() => {
        this.$model.user = this.$model.guest
        this.$router.push('/login')
      })
    }
  },
  watch: {
    user(current) {
      this.isGuest = !current.Id
    }
  }
})
</script>
